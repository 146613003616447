import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from 'moment';

import { Route, Switch, withRouter } from 'react-router-dom';
// core components
import MaterialTable from "material-table";
import DescriptionIcon from "@material-ui/icons/Description";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';

import Button from "components/CustomButtons/Button.js";
import Mood from '@material-ui/icons/Mood';
import MoodBad from '@material-ui/icons/MoodBad';
import lightGreen from '@material-ui/core/colors/lightGreen';

//import NewUser from "./components/NewReclutamiento";
//import EditReclutamiento from "./components/EditReclutamiento";
import ModalDelete from "./components/ModalDelete"
import { localization } from "variables/general.js";

import { toast } from 'react-toastify';


import { StateListReclutamientoCandidatos, ColumnsListadoDetalleCandidatos } from "./VariablesState";
import Upload from "./components/UploadArchivo";

//import "../Dashboard/chartlistcustom.css";

//import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js"
import ChartistGraph from "react-chartist";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


class ReclutamientoCandidatos extends Component {
  state = { ...StateListReclutamientoCandidatos };


  componentDidMount() {
    //  alert("Rec Detalle" );
    this.getDatosReclutamiento();
    this.getReclutamientoCandidatosAdmin();
  }


  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    })
  }
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE RECLUTAMIENTO DETALLE
  ////////////////////////
  ////////////////////////
  getReclutamientoCandidatosAdmin = () => {
    this.setState({
      isLoading: true
    })

    console.log("getReclutamientoCandidatosAdmin");
    console.log(this.props.match.params);
    console.log(this.props.idreclutamiento);
    //console.log(this.url);

    Database.get('/list-my-issues-by-status-rec/' + this.props.match.params.idetapa + "/"+ this.props.idreclutamiento, this, null, true)
      .then(res => {
        console.log(res);
        console.log(res.response.issues);
        let resultado = [...res.response.issues];
        console.log(resultado);

        resultado = resultado.map(elem => {
          return {
            ...elem,
            //   vencimiento_mostrar: moment(elem.vencimiento).isValid() ? moment(elem.vencimiento).format("DD/MM/YYYY hh:mm:ss") : "",
            //  fecha_linea_mostrar: elem.fields.customfield_10084 != null ? elem.fields.customfield_10084.value.substring(0, elem.fields.customfield_10084.value.indexOf("T")) : ""
            fecha_linea_mostrar: moment(elem.fields.customfield_10084).isValid() ? moment(elem.fields.customfield_10084).format("DD/MM/YYYY HH:mm:ss") : "",
            fecha_presentacion_mostrar: moment(elem.fields.customfield_10091).isValid() ? moment(elem.fields.customfield_10091).format("DD/MM/YYYY") : "",
          };
        });

        this.setState({
          isLoading: false,
          reclutamientoCandidatos: resultado,
          checked: [],
          menuContext: null,
        })


      }, err => {
        toast.error(err.message);

      })


  }


  getDatosReclutamiento = () => {
    console.log(this.props.match.params.idetapa);
    // Database.get('/datos-reclutamiento/' + this.props.match.params.idetapa)
    //   .then(resultado => {
    //     console.log(resultado);
    //     if (resultado.result.length > 0) {

    //       this.setState({
    //         reclutamientoEtapa: resultado.result[0].perfil
    //       })

    //     }
    //   })
  }

  handlePagination = offset => {
    this.setState({
      offset: offset
    })

  }

  // handleDeleteButton = rowData => {
  //   this.setState({
  //     openDeleteDialog: true,
  //     deleteRowData: rowData
  //   })
  // }

  menuHandleClose = (value) => {
    this.setState({
      menuContext: null
    })
  }

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    })
  }

  handleDeleteUser = rowData => {
    console.log(rowData.id);
    Database.post('/delete-issue', { id: rowData.id }, this).then(res => {

      this.setState({
        //   users: users,
        openDeleteDialog: false
      }, () => {
        toast.success("La solicitud de cancelación fue realizada con exito!. Recibirá respuesta a la brevedad.");
      })


    }, err => {
      toast.error(err.message);
    })

  }


  existeInformeFinal = rowData => {
    //    console.log(rowData.id);

    for (let archivo of rowData.fields.attachment) {
      //   console.log(archivo.filename);
      if (archivo.filename.toLowerCase().indexOf("informe") >= 0)
        if (archivo.filename.toLowerCase().indexOf("final") >= 0)
          return true;
      // if(archivo.filename == "informefinal.pdf")
      // return true;
    }

    return false;
  }

  handleBajarInforme = rowData => {
    console.log("bajando el informe del issue numero: " + rowData.id);

    let fileToDownload = "";

    for (let archivo of rowData.fields.attachment) {
      console.log(archivo.filename);
      if (archivo.filename.toLowerCase().indexOf("informe") >= 0)
        if (archivo.filename.toLowerCase().indexOf("final") >= 0) {
          fileToDownload = archivo.id;
          //    return;
        }
    }

    console.log(fileToDownload);


    if (fileToDownload != "") {

      var link = document.createElement("a");
      /// link.download = name;
      link.href = '/issuefile/' + fileToDownload;

      console.log(link.href);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;

      console.log("termino");
    }

  }

  feedbackPositivo = rowData => {
   
    console.log(rowData.id);

    //Database.get('/update-issue/', this, null)
    Database.post(`/update-issue`,
        {
          feedback_cliente: "10059"//"POSITIVO"
        },
        this)
        .then(res => {

          console.log(res);


        }, err => {
          toast.error(err.message);

        })


  }

  feedbackNegativo = rowData => {
    
    console.log(rowData.id);
  }



  render() {
    let style = {}
    /* if (this.props.match.url !== this.props.location.pathname) {
       style = { display: 'none' }
     }*/
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={style}>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite} >Búsquedas <b>{/*this.state.reclutamientoEtapa*/}</b></h4>
                <p className={this.props.classes.cardCategoryWhite} >
                  Listado de candidatos en la etapa
                </p>
              </CardHeader>
              <CardBody>

                {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoreclutamiento')} color="primary"><AddIcon /> Nuevo Reclutamiento</Button> */}
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListadoDetalleCandidatos}
                  data={this.state.reclutamientoCandidatos}
                  title=""
                  localization={localization}

                  actions={[
                    // {
                    //   icon: Mood,
                    //   tooltip: 'Feedback Positivo',
                    //   onClick: (event, rowData) => this.feedbackPositivo(rowData)

                    // },
                    //   {
                    //   icon: MoodBad,
                    //   tooltip: 'Feedback Negativo',
                    //   onClick: (event, rowData) => this.feedbackNegativo(rowData)
                    // },
                    // {
                    //   icon: 'delete',
                    //   tooltip: 'Solicitar Baja',
                    //   onClick: (event, rowData) => {this.handleDeleteButton(rowData)}

                    // }

                    rowData => (
                      {
                        icon: DescriptionIcon,
                        tooltip: 'Bajar Informe Entrevista',
                        onClick: (event, rowData) => this.handleBajarInforme(rowData),
                        disabled: this.existeInformeFinal(rowData) != true


                      })
                  ]}

                  components={{
                    Container: props => (
                      <Paper elevation={0} {...props} />
                    )
                  }}

                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: "ReclutamientoCandidatos " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: "#414141",
                      color: '#FFF'
                    },
                  }}
                />
              </CardBody>
            </Card>

            <Switch>

              <Route path={this.props.match.url + "/reclutamientocandidatos/:idetapa"} render={() =>

                <ReclutamientoCandidatos

                  getReclutamientoCandidatosAdmin={() => this.getReclutamientoCandidatosAdmin()}
                //     handleListNewUser={(rowData) => this.handleListNewUser(rowData)}


                />}
              />

            </Switch>

          </GridItem>

          <ModalDelete
            openDeleteDialog={this.state.openDeleteDialog}
            deleteRowData={this.state.deleteRowData}
            handleClose={() => this.handleModalClose()}
            handleDelete={(rowData) => this.handleDeleteUser(rowData)}
          />


        </GridContainer>
      </div>
    );
  }
}


export default withRouter(withStyles(styles)(ReclutamientoCandidatos));
