import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from 'moment';

import { Route, Switch, withRouter } from 'react-router-dom';
// core components
import MaterialTable from "material-table";
import DescriptionIcon from "@material-ui/icons/Description";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import lightGreen from '@material-ui/core/colors/lightGreen';

//import NewUser from "./components/NewReclutamiento";
//import EditReclutamiento from "./components/EditReclutamiento";
import ModalDelete from "./components/ModalDelete"
import { localization } from "variables/general.js";

import { toast } from 'react-toastify';

import ReclutamientoCandidatos from "./ReclutamientoCandidatos";
import { StateListReclutamientoDetalle, ColumnsListadoDetalle } from "./VariablesState";
import Upload from "./components/UploadArchivo";

//import "../Dashboard/chartlistcustom.css";

//import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js"
import ChartistGraph from "react-chartist";
import { Bar } from 'react-chartjs-2';


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};




class ReclutamientoDetalle extends Component {
  state = { ...StateListReclutamientoDetalle };


  componentDidMount() {
    //  alert("Rec Detalle" );
    this.getDatosReclutamiento();
    this.getReclutamientoDetalleAdmin();
    this.setState({
      serie: []
    });
  }


  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    })
  }
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE RECLUTAMIENTO DETALLE
  ////////////////////////
  ////////////////////////
  getReclutamientoDetalleAdmin = () => {
    this.setState({
      isLoading: true
    })


    /*
    Database.get('/list-issues-fields-rec', this, null, true)
    .then(res => {
      let resultado = [...res.response.issues];
      console.log(resultado);

      resultado = resultado.map(elem => {
        return {
          ...elem,
            };
      });

      this.setState({
        isLoading: false,
        reclutamientoDetalle: resultado,
        checked: [],
        menuContext: null,

      })
    }, err => {
      toast.error(err.message);
    })
*/


    Database.get('/list-my-statused-rec/' + this.props.match.params.idreclutamiento, this, null, true)
      .then(res => {
        console.log(res.status);
        let serie = [];
        let labels = [];

        const ids = [
          "10014",
          "10009",
          "10011",
          "10021",
          "10030",
          "10012",
          "10013"
        ];

        const sortedStatus = ids.map(id => res.status.find(s => s.id === id))

        sortedStatus.forEach(element => {
            console.log(element);
            serie.push(element.cantidad);
            labels.push(element.name);
          });

        console.log(serie);

        this.setState({
          isLoading: false,
          reclutamientoDetalle: sortedStatus,
          serie: serie,
          labels:labels,
          checked: [],
          menuContext: null,

        })

      }, err => {
        toast.error(err.message);

      })
  }


  getDatosReclutamiento = () => {

    Database.get('/datos-reclutamiento/' + this.props.match.params.idreclutamiento)
      .then(resultado => {
        console.log(resultado);
        if (resultado.result.length > 0) {

          this.setState({
            reclutamientoPerfil: resultado.result[0].perfil
          })

        }
      })
  }

  handlePagination = offset => {
    this.setState({
      offset: offset
    })

  }

  // handleDeleteButton = rowData => {
  //   this.setState({
  //     openDeleteDialog: true,
  //     deleteRowData: rowData
  //   })
  // }

  menuHandleClose = (value) => {
    this.setState({
      menuContext: null
    })
  }

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    })
  }

  handleDeleteUser = rowData => {
    console.log(rowData.id);
    Database.post('/delete-issue', { id: rowData.id }, this).then(res => {

      this.setState({
        //   users: users,
        openDeleteDialog: false
      }, () => {
        toast.success("La solicitud de cancelación fue realizada con exito!. Recibirá respuesta a la brevedad.");
      })


    }, err => {
      toast.error(err.message);
    })

  }

  mostrarEtapa = (rowData) => rowData.id !== "10014"

  /*
    Entrevista consultado 10009
    psicotecnico y referencias 10030
    finalistas 10012
    entrevistas con cliente 10021
    entrevista inicial con cliente 10011
    rechazados 10013
    solicitudes 10014
  */

  render() {
    let style = {}
    let issuesPorColumnaData = {
      options: {
        stackBars: true,
        width: "800px",
        height: "300px",
        classNames: {
          chart: 'ct-chart-line',
          label: 'ct-label-bar',
          labelGroup: 'ct-labels',
          series: 'ct-series',
          line: 'ct-line',
          point: 'ct-point',
          area: 'ct-area',
          grid: 'ct-grid',
          gridGroup: 'ct-grids',
          vertical: 'ct-vertical',
          horizontal: 'ct-horizontal',
          start: 'ct-start',
          end: 'ct-end'
        },


      },
      //  labels: ['Primer Cont.', 'Screening', 'Ent. Consultora', 'Ent. Cliente', 'Ent. Linea', 'Aceptado', 'Rechazado'],//['Bananas', 'Apples', 'Grapes'],
      series: this.state.serie//[20, 15, 40],

    };

    const data = {
      labels: this.state.labels,
      
      datasets: [
        {
         
          label:'Gráfico Barras',
          data: this.state.serie,
          backgroundColor: [
            'rgba(140, 139, 32,0.4)',
            'rgba(140, 139, 32,0.4)',
            
            'rgba(140, 139, 32,0.4)',
            'rgba(140, 139, 32,0.4)',
            'rgba(140, 139, 32,0.4)',
            'rgba(140, 139, 32,0.4)',
          ],
          borderColor: [
            'rgba(140, 139, 32,1)',
            'rgba(140, 139, 32,1)',
           
            'rgba(140, 139, 32,1)',
            'rgba(140, 139, 32,1)',
            'rgba(140, 139, 32,1)',
            'rgba(140, 139, 32,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    
    const options = {
      scales: {
        y: {
          grid: {
            display:false,
            color: 'white'
          }
        },
        x: {
          grid: {
            display:false,
            color: 'white'
          }
        }
      },
    };


    if (this.props.match.url !== this.props.location.pathname) {
      style = { display: 'none' }
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={style}>
              <CardHeader color="primary">
                <h4 className={this.props.classes.cardTitleWhite} >Búsquedas - Posición: <b>{this.state.reclutamientoPerfil}</b></h4>
                <p className={this.props.classes.cardCategoryWhite} >
                  Listado de candidatos para la búsqueda
                </p>
              </CardHeader>
              <CardBody>
              <Bar width={'500px'} data={data} options={options} />
              { /*
                <ChartistGraph
                  // style={"back"}
                  data={issuesPorColumnaData}
                  type={"Bar"}
                  options={issuesPorColumnaData.options}
                // responsiveOptions={[
                //   ['screen and (min-width: 640px)', {
                //     chartPadding: 20,
                //     labelOffset: 60,
                //     //  labelDirection: 'explode',
                //     labelInterpolationFnc: function (value) {
                //       return value;
                //     }
                //   }],
                //   ['screen and (min-width: 1024px)', {
                //     chartPadding: 20,
                //     labelOffset: 20
                //   }]
                // ]}     
                />
                */
              }

                {/* <Button style={{ marginTop: '25px' }} onClick={() => this.props.history.push(this.props.match.url + '/nuevoreclutamiento')} color="primary"><AddIcon /> Nuevo Reclutamiento</Button> */}
                <MaterialTable
                  isLoading={this.state.isLoading}
                  columns={ColumnsListadoDetalle}
                  data={this.state.reclutamientoDetalle}
                  title=""
                  localization={localization}

                  actions={[
                    // {
                    //   icon: 'search',
                    //   tooltip: 'Ver Detalle',
                    //   onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/reclutamientocandidatos/' + rowData.id)

                    // },
                    rowData => (
                      {
                        icon: 'search',
                        tooltip: 'Ver Detalle',
                        onClick: (event, rowData) => this.props.history.push(this.props.match.url + '/reclutamientocandidatos/' + rowData.id),
                        //onClick: (event, rowData) => this.handleBajarInforme(rowData),
                        hidden: this.mostrarEtapa(rowData) != true


                      })
                  ]}

                  components={{
                    Container: props => (
                      <Paper elevation={0} {...props} />
                    )
                  }}

                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: "ReclutamientoDetalle " + moment().format("DD-MM-YYYY"),
                    exportDelimiter: ";",
                    headerStyle: {
                      backgroundColor: "#414141",
                      color: '#FFF'
                    },
                  }}
                />
              </CardBody>
            </Card>

            <Switch>

              <Route path={this.props.match.url + "/reclutamientocandidatos/:idetapa"} render={() =>

                <ReclutamientoCandidatos

                  getReclutamientoDetalleAdmin={() => this.getReclutamientoDetalleAdmin()}
                  idreclutamiento = {this.props.match.params.idreclutamiento}
                //     handleListNewUser={(rowData) => this.handleListNewUser(rowData)}


                />}
              />

            </Switch>

          </GridItem>

          <ModalDelete
            openDeleteDialog={this.state.openDeleteDialog}
            deleteRowData={this.state.deleteRowData}
            handleClose={() => this.handleModalClose()}
            handleDelete={(rowData) => this.handleDeleteUser(rowData)}
          />


        </GridContainer>
      </div>
    );
  }
}


export default withRouter(withStyles(styles)(ReclutamientoDetalle));
